interface IErrorObj {
  unit_twr_device_name?: string,
  unit_zwave_device_name?: string,
  errors: Array<string>,
}
export const convertSyncErrors = (errors: Array<IErrorObj> | string | any) => {
  if (typeof errors === 'string') {
    return errors
  }
  if (Array.isArray(errors) && errors.length) {
    return errors
      .map((error: IErrorObj) => {
        const deviceName = error.unit_twr_device_name || error.unit_zwave_device_name || 'unknown'
        const longError = error.errors.map((e) => `'${e}' error`).join(', ')
        return `Synchronization of ${deviceName} device failed with ${longError}`
      })
      .join('; ')
  }
  return 'Synchronization completed with an unknown error'
}
