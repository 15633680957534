import { createLogic } from 'redux-logic'
import handleErrors from '@app/helpers/handleErrors'
import { SYNC_CANCEL_COUNTERS_CHANNEL, SYNC_COUNTERS_CHANNEL } from '@app/constants/syncCounters/countersChannel'
import {
  closeSyncInProgressPopup,
  openSyncErrorPopup,
  syncDoneChannel,
  syncFailed,
  syncSentChannel,
} from '@app/actions/syncCounters/countersChannel'
import { update } from '@app/actions/syncCounters/countersStatus'
import syncCountersChannel from '@app/api/syncCounters/syncCountersChannel'
import { syncType } from '@app/lib/Synchronize/syncTypes'
import { convertSyncErrors } from '@app/helpers/convertSyncErrors'

const subscribeCountersChannelLogic = createLogic({
  type: SYNC_COUNTERS_CHANNEL,
  cancelType: SYNC_CANCEL_COUNTERS_CHANNEL,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    syncCountersChannel({
      request: () => {
        dispatch(syncSentChannel())
      },
      response: (data) => {
        if (data.action === 'sync') {
          if (data.error) {
            store.dispatch(syncFailed(convertSyncErrors(data.error)))
            store.dispatch(closeSyncInProgressPopup())
            store.dispatch(openSyncErrorPopup(syncType.SYNC_COUNTERS))
          } else {
            store.dispatch(closeSyncInProgressPopup())
            store.dispatch(syncDoneChannel())
          }
        }
        if (data.action === 'update_counters') {
          if (data.error) {
            store.dispatch(syncFailed(handleErrors(data.error)))
            store.dispatch(openSyncErrorPopup(syncType.SYNC_COUNTERS))
          } else {
            store.dispatch(update(data.result))
            store.dispatch(syncDoneChannel())
          }
        }
        if (data.action === 'zones') {
          if (data.error) {
            store.dispatch(syncFailed(handleErrors(data.error)))
            store.dispatch(closeSyncInProgressPopup())
            store.dispatch(openSyncErrorPopup(syncType.SYNC_ZONES))
          } else {
            store.dispatch(closeSyncInProgressPopup())
            store.dispatch(syncDoneChannel())
          }
        }
      },
    })
  },
})

export default subscribeCountersChannelLogic
